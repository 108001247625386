import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Collapse,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Check from 'components/Check';
import numberToPercentage from 'utils/numberToPercentage';
import { useLocation, Link } from 'react-router-dom';
import CaseNavigation from 'components/CaseNavigation';
import classnames from 'classnames';
import { useSession } from 'utils/useSession';
import { useTranslation } from 'react-i18next';
import EditableFeedback from 'components/EditableFeedback';
import moment from 'moment';
import SummaryV2 from 'components/SummaryV2';

const GET_NEXT_RECALC_WINDOW = gql`
  query getNextRecalcWindow($id: ID!) {
    getAlbumRecalcWindow(id: $id) {
      id
      recalcWindow
    }
  }
`;

function getScheduledAggegation(recalcWindowData) {
  const recalcWindow = recalcWindowData?.getAlbumRecalcWindow?.recalcWindow;
  if (!recalcWindow) {
    return null;
  }
  const recalcWindowDate = moment(recalcWindow);
  const nowDate = moment();

  if (recalcWindowDate.isAfter(nowDate)) {
    return recalcWindowDate.format('YYYY-MM-DD HH:mm:ss');
  }

  return null;
}

function getCaseIdFromUrl(url) {
  const tokens = url.split('/');
  return tokens[2];
}

function CaseDetailHeader({
  id,
  caseName,
  isCar,
  brandName,
  modelName,
  hasAllViews,
  caseAggregation,
  exifStatus,
  viewAggragation,
  status,
  summary,
  summaryV2,
  isAnnotable
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: recalcWindowData } = useQuery(GET_NEXT_RECALC_WINDOW, {
    variables: { id }
  });
  const { sessionVariable, setSessionVariable } = useSession();
  const [isOpen, setIsOpen] = useState(
    sessionVariable('case-detail-show-aggegation')
  );
  const currentCaseId = getCaseIdFromUrl(location.pathname);
  const filteredCaseIds =
    location.state && location.state.filteredCaseIds
      ? location.state.filteredCaseIds
      : [];
  const nextRecalc = recalcWindowData
    ? getScheduledAggegation(recalcWindowData)
    : null;

  const toggleShowAggregation = () => {
    setSessionVariable('case-detail-show-aggegation', !isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="header pb-6 pt-5 pt-md-8 bg-gradient-info">
        <Container fluid>
          <CaseNavigation
            currentCaseId={currentCaseId}
            filteredCaseIds={filteredCaseIds}
          />
          <div className="header-body">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h1 className="">{caseName}</h1>
                          </div>
                          <div className="d-flex">
                            <h1>
                              {isCar && <i className="fas fa-car pl-6" />}
                              <span className="pl-2 text-capitalize">
                                {brandName && brandName}{' '}
                                {modelName && modelName}
                              </span>
                              <span className="pl-2" id="hasAllViews">
                                {hasAllViews ? (
                                  <i className="far fa-eye text-success" />
                                ) : (
                                  <i className="far fa-eye text-warning" />
                                )}
                              </span>
                              <UncontrolledTooltip
                                delay={0}
                                placement="bottom"
                                target="hasAllViews"
                              >
                                {hasAllViews
                                  ? t('case.all_views_provided')
                                  : t('case.all_views_not_provided')}
                              </UncontrolledTooltip>
                            </h1>
                            {isAnnotable && (
                              <div className="ml-4">
                                <Link
                                  to={{
                                    pathname: `/cases/${currentCaseId}/annotate`
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button color="info" type="button">
                                    {t('case.annotate')}
                                  </Button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Row>
                          <Col className="d-flex justify-content-between flex-row align-items-center">
                            <div className="card-profile-stats d-flex justify-content-center p-0">
                              <div className="m-0">
                                <span className="heading">
                                  {caseAggregation?.allViews
                                    ? t('general.yes')
                                    : t('general.no')}
                                </span>
                                <span className="description">
                                  {t('case.all_required_views')}
                                </span>
                              </div>
                              <div className="m-0">
                                <span className="heading">
                                  {exifStatus
                                    ? t(
                                        `exif_statuses.${exifStatus?.toLowerCase()}`
                                      )
                                    : t('exif_statuses.na')}
                                </span>
                                <span className="description">
                                  {t('case.exif_summary')}
                                </span>
                              </div>
                              <div className="m-0">
                                <span className="heading">
                                  {' '}
                                  {nextRecalc
                                    ? `${nextRecalc}`
                                    : t('general.no')}
                                </span>
                                <span className="description">
                                  {t('case.next_recalculation')}
                                </span>
                              </div>
                            </div>

                            <div>
                              <EditableFeedback caseId={id} />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!status && (
                      <Row>
                        <Col className="text-center">
                          <h2 className="text-gray mt-3">
                            {t('case.not_calculated')}
                          </h2>
                        </Col>
                      </Row>
                    )}
                    <Collapse isOpen={isOpen}>
                      {summary && !summaryV2 && (
                        <Row className="mt-4">
                          <Col>
                            {caseAggregation && (
                              <>
                                <h3 className="text-capitalize">
                                  {t('case.case_summary')}
                                </h3>
                                <Table
                                  className="align-items-center"
                                  responsive
                                >
                                  <tbody>
                                    <tr>
                                      <td className="text-capitalize">
                                        {t('case.manafacturer')}
                                      </td>
                                      <td>
                                        {caseAggregation.manafacturer.label}
                                      </td>
                                      <td>
                                        {numberToPercentage(
                                          caseAggregation.manafacturer.score
                                        )}{' '}
                                        %
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-capitalize">
                                        {t('case.model')}
                                      </td>
                                      <td>{caseAggregation.model.label}</td>
                                      <td>
                                        {numberToPercentage(
                                          caseAggregation.model.score
                                        )}{' '}
                                        %
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-capitalize">
                                        {t('case.color')}
                                      </td>
                                      <td>{caseAggregation.color.label}</td>
                                      <td>
                                        {numberToPercentage(
                                          caseAggregation.color.score
                                        )}{' '}
                                        %
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-capitalize">
                                        {t('case.car')}
                                      </td>
                                      <td />
                                      <td>
                                        <Check
                                          checked={caseAggregation.caseIsCar}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-capitalize">
                                        {t('case.required_views_present')}
                                      </td>
                                      <td />
                                      <td>
                                        <Check
                                          checked={caseAggregation.allViews}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-capitalize">
                                        {t('case.exif_status')}
                                      </td>
                                      <td>
                                        {t(
                                          `exif_statuses.${caseAggregation?.exifSummary?.toLowerCase()}`
                                        )}
                                      </td>
                                      <td />
                                    </tr>
                                  </tbody>
                                </Table>
                              </>
                            )}
                          </Col>
                          <Col>
                            <h3 className="text-capitalize">
                              {t('case.views')}
                            </h3>
                            <Table className="align-items-center" responsive>
                              <tbody>
                                {viewAggragation &&
                                  viewAggragation.map(item => (
                                    <tr key={item.expected}>
                                      <td>
                                        {t(
                                          `views.${item?.expected?.toLowerCase()}`
                                        )}
                                      </td>
                                      <td>{item.count}</td>
                                      <td>
                                        <Check checked={item.check} />
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </Col>
                          <Col>
                            <h3 className="text-capitalize">
                              {t('case.exif_summary')}
                            </h3>
                            <Table className="align-items-center" responsive>
                              <tbody>
                                {(!summary?.exifSummary ||
                                  summary?.exifSummary?.length <= 0) && (
                                  <h5>No data available</h5>
                                )}
                                {summary?.exifSummary &&
                                  summary.exifSummary?.length > 0 &&
                                  summary.exifSummary.map(({ key, value }) => (
                                    <tr key={key?.toLowerCase()}>
                                      <td>
                                        {t(
                                          `exif_summaries.${key?.toLowerCase()}`
                                        )}
                                      </td>
                                      <td>
                                        {t([
                                          `exif_summaries.${value?.toLowerCase()}`,
                                          value?.replace(':', ' ')
                                        ])}
                                      </td>
                                      <td />
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      )}
                      {summaryV2 && (
                        <Row className="mt-4">
                          {summaryV2.map(({ title, rows }) => (
                            <Col>
                              <SummaryV2 title={title} rows={rows} />
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Collapse>
                    <Row className="mt-4">
                      <Col>
                        <div
                          className={classnames('text-center', {
                            'd-none': !status
                          })}
                        >
                          <p
                            className="card__collapse__toggle"
                            onClick={() => toggleShowAggregation()}
                          >
                            {!isOpen
                              ? t('case.show_aggregations')
                              : t('case.hide_aggregations')}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CaseDetailHeader.propTypes = {
  id: PropTypes.string.isRequired,
  caseName: PropTypes.string.isRequired,
  isCar: PropTypes.bool.isRequired,
  brandName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  hasAllViews: PropTypes.bool.isRequired,
  caseAggregation: PropTypes.shape({
    manafacturer: PropTypes.shape({
      label: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired
    }),
    model: PropTypes.shape({
      label: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired
    }),
    color: PropTypes.shape({
      label: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired
    }),
    caseIsCar: PropTypes.bool.isRequired,
    allViews: PropTypes.bool.isRequired,
    exifSummary: PropTypes.string.isRequired
  }),
  viewAggragation: PropTypes.arrayOf(
    PropTypes.shape({
      expected: PropTypes.string.isRequired,
      check: PropTypes.bool.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired,
  status: PropTypes.bool.isRequired,
  exifStatus: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    exifSummary: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    )
  }),
  summaryV2: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          layout: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          label: PropTypes.string,
          count: PropTypes.number,
          score: PropTypes.number,
          check: PropTypes.bool
        })
      )
    })
  ),
  isAnnotable: PropTypes.bool
};

CaseDetailHeader.defaultProps = {
  caseAggregation: null,
  summary: null,
  summaryV2: null,
  isAnnotable: false
};

export default CaseDetailHeader;
