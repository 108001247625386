function compareByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}

function compareByCreatedAtDesc(a, b) {
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  return 0;
}

function compareByNameAsc(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function comparePhotosBySourceFilenameAsc(a, b) {
  if (a.sourceFilename < b.sourceFilename) {
    return -1;
  }
  if (a.sourceFilename > b.sourceFilename) {
    return 1;
  }
  return 0;
}

function comparePhotosByPageNumberAsc(a, b) {
  const aPageNumber = parseInt(
    a?.metadata?.find(i => i.key === 'pageNumber')?.value,
    10
  );
  const bPageNumber = parseInt(
    b?.metadata?.find(i => i.key === 'pageNumber')?.value,
    10
  );

  if (aPageNumber < bPageNumber) {
    return -1;
  }
  if (aPageNumber > bPageNumber) {
    return 1;
  }
  return 0;
}

export {
  compareByCreatedAt,
  compareByCreatedAtDesc,
  compareByNameAsc,
  comparePhotosBySourceFilenameAsc,
  comparePhotosByPageNumberAsc
};
