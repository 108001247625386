import { Storage } from 'aws-amplify';
import removePublicPrefix from 'utils/removePublicPrefix';

function labelAndTextFromResultText(resultText) {
  if (!resultText) {
    return {};
  }
  const parsedResultText = JSON.parse(resultText);
  const { label } = parsedResultText[0];
  const { text } = parsedResultText[0];
  return { label, text };
}

// lastUpdatedModelResults choose either modelResults or editedModelResults
function lastUpdatedModelResults(photo) {
  if (photo?.editedModelResults?.v1?.length > 0) {
    return photo.editedModelResults.v1;
  }
  if (photo.modelResults && photo.modelResults.v1) {
    return photo.modelResults.v1;
  }
  return [];
}

function extractionEngineModelResultsToRegions(modelResults) {
  return modelResults.map(r => {
    const { label, text } = labelAndTextFromResultText(r.text);
    return {
      id: r.id,
      type: 'box',
      visible: r.label !== 'metadata',
      cls: label,
      highlighted: false,
      groupHighlighted: false,
      x: r.box.x1,
      y: r.box.y1,
      w: r.box.x2 - r.box.x1,
      h: r.box.y2 - r.box.y1,
      groupId: r.groupId,
      text
    };
  });
}

// previousArticleId, artuicleType, advertisementType
function metadataEngineModelResultsToRegions(modelResults) {
  // group metadata by groupId
  const metadataByGroupId = modelResults.reduce((prev, curr) => {
    const { id: metadataId, groupId, label: key, text: value } = curr;
    const newPrev = { ...prev };
    if (!newPrev[groupId]) {
      newPrev[groupId] = [];
    }
    newPrev[groupId].push({ metadataId, key, value });
    return newPrev;
  }, {});

  // convert groups to Editor friendly format
  const regions = Object.keys(metadataByGroupId).map(key => ({
    id: key,
    type: 'box',
    visible: false,
    cls: 'metadata',
    highlighted: false,
    groupHighlighted: false,
    x: 0,
    y: 0,
    w: 1,
    h: 1,
    groupId: key,
    text: JSON.stringify([...metadataByGroupId[key]])
  }));

  return regions;
}

function modelResultsToRegions(
  modelResults,
  extractionEngineClassNames,
  metadataEngineClassNames
) {
  const extractionEngineRegions = extractionEngineModelResultsToRegions(
    modelResults.find(mr => extractionEngineClassNames.includes(mr.name))
      ?.results || []
  );

  const metadataEngineRegions = metadataEngineModelResultsToRegions(
    modelResults.find(mr => metadataEngineClassNames.includes(mr.name))
      ?.results || []
  );

  return [...extractionEngineRegions, ...metadataEngineRegions];
}

async function photosToImages(
  photos,
  extractionEngineClassNames,
  metadataEngineClassNames
) {
  return Promise.all(
    photos.map(async photo => {
      const fullsizeSignedURL = await Storage.get(
        removePublicPrefix(photo.fullsize.key),
        {
          expires: 60 * 60
        }
      );
      const thumbnailSignedURL = await Storage.get(
        removePublicPrefix(photo.thumbnail.key),
        {
          expires: 60 * 60
        }
      );

      return {
        id: photo.id,
        src: fullsizeSignedURL,
        thumbnail: thumbnailSignedURL,
        originalSrc: photo.fullsize.key,
        originalThumbnail: photo.thumbnail.key,
        name: photo.fullsize.key,
        regions: modelResultsToRegions(
          lastUpdatedModelResults(photo),
          extractionEngineClassNames,
          metadataEngineClassNames
        ),
        metadata: photo.metadata || [],
        lockedUntil:
          Date.parse(photo.lockedUntil) > new Date() ? photo.lockedUntil : null
      };
    })
  );
}

export default photosToImages;
