import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import picture from 'assets/img/brand/SEARPENT_animated_150px.gif';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

const requiredFieldPrefix = 'required-field_';

const SEND_INVITATION = gql`
  mutation sendInvitationSMS($input: SendInvitationSMSInput!) {
    sendInvitationSMS(input: $input) {
      id
      name
      createdAt
      status
      summary {
        iscar
        model {
          label
        }
        color {
          label
        }
        exifstatus
        allviews
      }
      feedback {
        casecheck
        exiferror
        modelerror
      }
    }
  }
`;

function getRequiredFieldsVars(values) {
  const keys = Object.keys(values).filter(k =>
    k.startsWith(requiredFieldPrefix)
  );
  const requiredFields = keys.map(k => {
    return {
      fieldId: k.replace(requiredFieldPrefix, ''),
      requiredText: values[k]
    };
  });
  return requiredFields;
}

function SendInvitationForm({ sources, onClose, defaultSource }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    reset
  } = useForm({
    defaultValues: {
      source: defaultSource
    }
  });
  const source = watch('source');
  const [sendInvitationSMS, { loading }] = useMutation(SEND_INVITATION, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          listInspections(existingItems = []) {
            const newItemRef = cache.writeFragment({
              data: data.sendInvitationSMS,
              fragment: gql`
                fragment NewInspection on Inspection {
                  id
                  name
                  createdAt
                  status
                  summary {
                    iscar
                    model {
                      label
                    }
                    color {
                      label
                    }
                    exifstatus
                    allviews
                  }
                  feedback {
                    casecheck
                    exiferror
                    modelerror
                  }
                }
              `
            });
            const items = [newItemRef, ...existingItems.items];
            return {
              ...existingItems,
              items
            };
          }
        }
      });
    }
  });

  const onSubmit = async data => {
    const vars = {
      source: data.source,
      name: data.name,
      phone: data.phone,
      invitationMessage: data.invitationMessage,
      requiredFields: getRequiredFieldsVars(data)
    };
    try {
      await sendInvitationSMS({
        variables: { input: vars }
      });
      toast.success(t('invitation.invite_via_sms_success'));
      reset();
      onClose();
    } catch (error) {
      toast.error(t('invitation.invite_via_sms_failure', { error }));
    }
  };

  const selectedSource = sources.filter(s => s.filterName === source)[0];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('invitation.invite_via_sms')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="name">
                {t('invitation.name')}
              </Label>
              <Input
                className={classnames({
                  'is-invalid': errors.name
                })}
                type="text"
                {...register('name', { required: true })}
                autoFocus
                onBlur={() => trigger('name')}
              />
              <div className="form-control-feedback pt-1 text-left">
                {errors.name?.type === 'required' &&
                  t('general.required_field')}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="name">
                {t('invitation.phone_number')}
              </Label>
              <Input
                type="text"
                className={classnames({
                  'is-invalid': errors.phone
                })}
                {...register('phone', {
                  required: true,
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/im
                })}
                onBlur={() => trigger('phone')}
                placeholder={t('invitation.phone_number_placeholder')}
              />
              <div className="form-control-feedback pt-1 text-left">
                {errors.phone?.type === 'required' &&
                  t('general.required_field')}
                {errors.phone?.type === 'pattern' &&
                  t('invitation.phone_invalid_format')}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="invitationMessage">
                {t('invitation.invitation_message')}
              </Label>
              <Input
                type="text"
                maxLength="120"
                className={classnames({
                  'is-invalid': errors.invitationMessage
                })}
                {...register('invitationMessage', {
                  pattern: /^.*%s.*/,
                  maxLength: 120
                })}
                onBlur={() => trigger('invitationMessage')}
                placeholder={t('invitation.invitation_message_placeholder')}
              />
              <div className="form-control-feedback pt-1 text-left">
                {errors.invitationMessage?.type === 'pattern' &&
                  t('invitation.invitation_message_must_contain')}
                {errors.invitationMessage?.type === 'maxLength' &&
                  t('invitation.invitation_message_max_length')}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="source">
                {t('invitation.source')}
              </Label>
              <select
                name="source"
                {...register('source', { required: true })}
                id="source"
                className={classnames('form-control', {
                  'is-invalid': errors.phone
                })}
              >
                <option value="" disabled>
                  {t('general.select')}
                </option>
                {sources.map(s => (
                  <option key={s.filterName} value={s.filterName}>
                    {s.name}
                  </option>
                ))}
              </select>
              <div className="form-control-feedback pt-1 text-left">
                {errors.source?.type === 'required' &&
                  t('general.required_field')}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {selectedSource && (
            <Col xs="12">
              <h5 className="mt-4 mb-4">{t('invitation.required_fields')}</h5>
            </Col>
          )}
          {selectedSource?.requiredFields.map(rf => (
            <Col xs="12">
              <FormGroup>
                <Label className="form-control-Label" htmlFor="name">
                  {rf.label}
                </Label>
                <Input
                  type="text"
                  {...register(`${requiredFieldPrefix}${rf.fieldId}`, {
                    required: true
                  })}
                  className={classnames({
                    'is-invalid': errors[`${requiredFieldPrefix}${rf.fieldId}`]
                  })}
                  onBlur={() => trigger(`${requiredFieldPrefix}${rf.fieldId}`)}
                />
                <div className="form-control-feedback pt-1 text-left">
                  {errors[`${requiredFieldPrefix}${rf.fieldId}`]?.type ===
                    'required' && t('general.required_field')}
                </div>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          {t('general.cancel')}
        </Button>
        <Button color="success" type="submit">
          {loading && (
            <img
              src={picture}
              alt="loading"
              style={{ height: '2rem' }}
              className="mr-2"
            />
          )}
          {!loading ? t('invitation.send_sms') : t('invitation.sending_sms')}
        </Button>
      </div>
    </form>
  );
}

SendInvitationForm.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.string.isRequired,
      requiredFields: PropTypes.array
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  defaultSource: PropTypes.string.isRequired
};

export default SendInvitationForm;
