import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Annotator from '@searpent/react-image-annotate';
import { HotKeys } from 'react-hotkeys';
import defaultRegionClassList from 'variables/defaultRegionClassList';
import useAnnotation from 'utils/useAnnotation';
import { greyscale } from 'variables/annotatorRegionGroupColors';
import Loader from '../components/Loader';
import ItemFailedToLoad from '../components/ItemFailedToLoad';
import { defaultKeyMap } from '../components/Annotation/ShortcutsManager';

function Annotation() {
  const { id: albumId } = useParams();
  const { t } = useTranslation();
  const {
    loading,
    error,
    isReady,
    images,
    albumMetadata,
    metadataConfigs,
    classColors,
    save,
    fetchImage
  } = useAnnotation(albumId);

  if (loading) return <Loader title={t('general.loading_data')} />;
  if (error)
    return <ItemFailedToLoad error={new Error('failed to load data')} />;
  if (!isReady) return <Loader title="Case not ready yet" />;

  if (images.length > 0)
    return (
      <HotKeys keyMap={defaultKeyMap}>
        <Annotator
          labelImages
          regionClsList={defaultRegionClassList}
          images={images}
          clsColors={classColors}
          enabledTools={[]}
          groupColors={greyscale}
          hideHeader
          hideHistory
          hideNotEditingLabel
          showEditor
          showPageSelector
          albumMetadata={albumMetadata}
          metadataConfigs={metadataConfigs}
          save={save}
          fetchImage={fetchImage}
          help={`# Tools\n\n**E** - select tool\n\n**B** - frame tool\n\n**R** - reset screen zoom\n\n**Ctrl** + **click frame** - edit fram\n\n**Del** - delete frame\n\n**1**- **9** - change class\n\n\n\n**click frame**, **B** - activate creeating frames over existing areas`}
        />
      </HotKeys>
    );

  return null;
}

export default Annotation;
