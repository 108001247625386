import React from 'react';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import CasesHeader from 'components/Headers/CasesHeader';
import CasesTable from 'components/Tables/CasesTable';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useURLFilter } from 'utils/useFilter';
import addTimeToDates from 'utils/addTimeToDates';
import { useAccount } from 'utils/useAccount';
import FullscreenInfo from 'components/FullscreenInfo';

const GET_CASES = gql`
  query getCases($filter: ListAlbumsInput!) {
    listAlbums(filter: $filter) {
      items {
        id
        name
        createdAt
        status
        source
        summary {
          iscar
          model {
            label
          }
          make {
            label
          }
          color {
            label
          }
          exifstatus
          allviews
        }
        feedback {
          casecheck
          exiferror
          modelerror
        }
      }
    }
  }
`;
function normalizeCases(graphCases, sources) {
  const tableCases = graphCases.map(c => {
    const carModel =
      c.summary && c.summary.model && c.summary.model.label
        ? c.summary.model.label
        : '';
    const carMake =
      c.summary && c.summary.make && c.summary.make.label
        ? c.summary.make.label
        : '';
    return {
      id: c.id,
      name: c.name,
      createdAt: c.createdAt,
      status: c.status,
      isCar: c.summary ? c.summary.iscar : undefined,
      carModel: `${carMake} ${carModel}`,
      color: c.summary && c.summary.color ? c.summary.color.label : null,
      exifStatus: c.summary ? c.summary.exifstatus : undefined,
      views: c.summary ? c.summary.allviews : undefined,
      feedback: c.feedback,
      source: c.source,
      isAnnotable: !!sources.find(s => s.filterName === c.source)
        ?.annotationConfig
    };
  });
  return tableCases;
}

function Cases() {
  const { t } = useTranslation();
  const { filter } = useURLFilter();
  const { sources } = useAccount();
  const { data, loading, error, refetch } = useQuery(GET_CASES, {
    variables: {
      filter: addTimeToDates(filter)
    }
  });

  if (!filter.source || error)
    return <FullscreenInfo message={t('general.no_data_to_display')} />;

  return (
    <>
      <CasesHeader onCaseAdded={refetch} />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 text-capitalize">{t('general.cases')}</h3>
              </CardHeader>
              {loading && <Loader title={t('general.loading_data')} />}
              {data && (
                <CasesTable
                  items={normalizeCases(data.listAlbums.items, sources)}
                />
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Cases;
