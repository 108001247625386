import { v4 } from 'uuid';

function addMetadataToRegions(regions, metadataConfigs) {
  const groupIds = regions.map(region => region.groupId);
  const uniqueGroupIds = [...new Set(groupIds)];
  const newRegions = [...regions];

  // eslint-disable-next-line no-restricted-syntax
  for (const uniqueGroupId of uniqueGroupIds) {
    if (
      newRegions.findIndex(
        r => r.groupId === uniqueGroupId && r.cls === 'metadata'
      ) === -1
    ) {
      newRegions.push({
        id: v4(),
        cls: 'metadata',
        groupHighlighted: false,
        editingLabels: false,
        groupId: uniqueGroupId,
        highlighted: false,
        text: JSON.stringify(
          metadataConfigs.map(mc => ({
            key: mc.key,
            value: '',
            metadataId: v4()
          }))
        ),
        type: 'box',
        visible: false,
        h: 1,
        w: 1,
        x: 0,
        y: 0
      });
    }
  }

  return newRegions;
}

export default addMetadataToRegions;
