import { useCallback, useEffect, useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { useAccount } from 'utils/useAccount';
import defaultClsColors from 'variables/defaultClsColors';
import { comparePhotosByPageNumberAsc } from 'utils/compareFunctions';
import photosToImages from 'utils/photosToImages';
import addLevelMetadata from 'utils/addLevelMetadata';
import addMetadataToRegions from 'utils/addMetadataToRegions';
import gql from 'graphql-tag';
import extractionRegionsToModelResults from 'utils/extractionRegionsToModelResults';
import metadataRegionsToModelResults from 'utils/metadataRegionsToModelResults';
import isAlbumReady from 'utils/isAlbumReady';
import hasRegionsForAnnotation from './hasRegionsForAnnotation';
import regionsGroups from './regionsGroups';

const initialAnnotationData = {
  images: [],
  albumMetadata: [],
  classColors: defaultClsColors,
  metadataConfigs: [],
  error: null,
  isReady: false,
  loading: true
};

const GET_ALBUM_NO_PHOTOS_DETAIL_QUERY = gql`
  query getAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      source
      name
      metadata {
        key
        value
      }
    }
  }
`;

const GET_ALBUM_PHOTOS_QUERY = gql`
  query getAlbumPhotos($id: ID!, $nextToken: String) {
    getAlbumPhotos(id: $id, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        thumbnail {
          height
          width
          key
        }
        fullsize {
          height
          width
          key
        }
        lockedUntil
        sourceFilename
        modelResults {
          v1 {
            name
            modelFamily
            results {
              id
              label
              score
              box {
                x1: X1
                x2: X2
                y1: Y1
                y2: Y2
              }
              groupId
              text
            }
          }
        }
        editedModelResults {
          v1 {
            name
            modelFamily
            results {
              id
              label
              score
              box {
                x1: X1
                x2: X2
                y1: Y1
                y2: Y2
              }
              groupId
              text
            }
          }
        }
        metadata {
          key
          value
        }
      }
      nextToken
    }
  }
`;

const UPDATE_ALBUM_METADATA_MUTATION = gql`
  mutation updateAlbumMetadata($albumId: ID!, $metadata: [MetadataInput]) {
    updateAlbumMetadata(input: { id: $albumId, metadata: $metadata }) {
      id
      metadata {
        key
        value
      }
    }
  }
`;

const UPDATE_PHOTO_METADATA_MUTATION = gql`
  mutation updatePhotoMetadata(
    $id: ID!
    $albumId: String!
    $metadata: [MetadataInput]
  ) {
    updatePhotoMetadata(
      input: { id: $id, albumId: $albumId, metadata: $metadata }
    ) {
      id
      metadata {
        key
        value
      }
    }
  }
`;

const UPDATE_EDITED_MODEL_RESULTS_MUTATION = gql`
  mutation updateEditedModelResults(
    $id: String!
    $albumId: String!
    $editedModelResults: ModelResultsVersionInput!
  ) {
    updateEditedModelResults(
      input: {
        id: $id
        albumId: $albumId
        editedModelResults: $editedModelResults
      }
    ) {
      id
    }
  }
`;

const RECALCULATE_PHOTO_MUTATION = gql`
  mutation recalculatePhoto($id: ID!, $albumId: String!) {
    recalculatePhoto(input: { id: $id, albumId: $albumId }) {
      id
      lockedUntil
    }
  }
`;

const FETCH_PHOTO_QUERY = gql`
  query fetchPhoto($id: ID!, $albumId: String!) {
    getPhoto(albumId: $albumId, id: $id) {
      id
      createdAt
      thumbnail {
        height
        width
        key
      }
      fullsize {
        height
        width
        key
      }
      lockedUntil
      sourceFilename
      modelResults {
        v1 {
          name
          modelFamily
          results {
            id
            label
            score
            box {
              x1: X1
              x2: X2
              y1: Y1
              y2: Y2
            }
            groupId
            text
          }
        }
      }
      editedModelResults {
        v1 {
          name
          modelFamily
          results {
            id
            label
            score
            box {
              x1: X1
              x2: X2
              y1: Y1
              y2: Y2
            }
            groupId
            text
          }
        }
      }
      metadata {
        key
        value
      }
    }
  }
`;

function useFetchAlbumWithPagination(albumId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(undefined);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchAndUpdateAlbumAndPhotos = async () => {
      // fetch album without photos
      const {
        data: getAlbumData,
        error: getAlbumError
      } = await apolloClient.query({
        query: GET_ALBUM_NO_PHOTOS_DETAIL_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          id: albumId
        }
      });

      if (getAlbumError) {
        setError('failed to load album data');
        return;
      }

      // fetch photos in loop
      let photos = [];
      let allPhotosLoaded = false;
      let nextToken;

      while (allPhotosLoaded === false) {
        const {
          data: { getAlbumPhotos },
          error: listPhotosError
        } = await apolloClient.query({
          query: GET_ALBUM_PHOTOS_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            id: albumId,
            nextToken
          }
        });

        if (listPhotosError) {
          setError('failed to load photos of album');
          return;
        }

        photos = [...photos, ...getAlbumPhotos.items];
        if (getAlbumPhotos.nextToken) {
          nextToken = getAlbumPhotos.nextToken;
        } else {
          allPhotosLoaded = true;
        }
      }

      // compose results together
      const result = {
        getAlbum: {
          ...getAlbumData.getAlbum,
          photos
        }
      };

      setData(result);
    };

    fetchAndUpdateAlbumAndPhotos();
  }, [albumId, apolloClient]);

  return {
    data,
    error
  };
}

// useAnnotation loads album entity with photos and transform it to images, albumMetadata and other neccessarities for Annotator component
function useAnnotation(albumId) {
  const apolloClient = useApolloClient();
  const { sources, modelClasses } = useAccount();
  const { error, data } = useFetchAlbumWithPagination(albumId);
  const [
    {
      images,
      albumMetadata,
      error: annotationDataError,
      classColors,
      isReady,
      metadataConfigs,
      loading
    },
    setAnnotationData
  ] = useState(initialAnnotationData);

  const save = useCallback(
    async ({ image, triggerRecalc, albumMetadata: am }) => {
      let lockedUntil = null;

      // update album metadata
      if (am?.length > 0) {
        await apolloClient.mutate({
          mutation: UPDATE_ALBUM_METADATA_MUTATION,
          variables: {
            albumId,
            metadata: am
          }
        });
      }

      // update photo
      if (image) {
        // photo metadata
        if (image?.metadata?.length > 0) {
          await apolloClient.mutate({
            mutation: UPDATE_PHOTO_METADATA_MUTATION,
            variables: {
              id: image.id,
              albumId,
              metadata: image.metadata
            }
          });
        }

        // photo edited model results
        // prepare regions
        const extractionEngineModelResults = extractionRegionsToModelResults(
          image.regions.filter(r => r.cls !== 'metadata')
        );
        const metadataEngineModelResults = metadataRegionsToModelResults(
          image.regions.filter(r => r.cls === 'metadata')
        );
        // update regions
        const variables = {
          id: image.id,
          albumId,
          editedModelResults: {
            v1: [
              {
                modelFamily: 'extraction-engine',
                name: 'extraction-engine',
                results: extractionEngineModelResults
              },
              {
                modelFamily: 'metadata-engine',
                name: 'metadata-engine',
                results: metadataEngineModelResults
              }
            ]
          }
        };
        await apolloClient.mutate({
          mutation: UPDATE_EDITED_MODEL_RESULTS_MUTATION,
          variables
        });
      }

      // recalc
      if (triggerRecalc) {
        const { data: recalculatePhotoData } = await apolloClient.mutate({
          mutation: RECALCULATE_PHOTO_MUTATION,
          variables: {
            id: image.id,
            albumId
          }
        });

        lockedUntil = recalculatePhotoData.recalculatePhoto.lockedUntil;
      }

      return {
        lockedUntil
      };
    },
    [albumId, apolloClient]
  );

  const fetchImage = useCallback(
    async ({ imageId }) => {
      const { data: fetchImageData } = await apolloClient.query({
        query: FETCH_PHOTO_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          albumId,
          id: imageId
        }
      });

      const { getPhoto: photo } = fetchImageData;
      const parsedImages = await photosToImages(
        [photo],
        ['extraction-engine'],
        ['metadata-engine']
      );

      return {
        image: parsedImages[0]
      };
    },
    [apolloClient, albumId]
  );

  useEffect(() => {
    if (!data || sources.length < 1) return;

    // parse album and set Annotation data
    const updateAnnotationData = async album => {
      // if there are no regions for annotation, stop
      if (!hasRegionsForAnnotation(album, sources)) {
        setAnnotationData(prev => ({
          ...prev,
          isReady: false,
          loading: false
        }));
        return;
      }

      // find source configuration for this album
      const source = sources.find(s => s.filterName === album.source);
      if (!source?.annotationConfig?.models) {
        setAnnotationData(prev => ({
          ...prev,
          error: new Error('there is no annotation config for this source'),
          loading: false
        }));
        return;
      }

      // convert photos to images (async because S3 link need to be generated)
      const imgs = await photosToImages(
        [...album.photos].sort(comparePhotosByPageNumberAsc),
        source.annotationConfig.models,
        ['metadata-engine']
      );
      const mtCnfgs =
        [...(source.annotationConfig?.metadataConfigs || [])] || [];

      // extract all articles
      const articles = imgs.reduce((acc, curr) => {
        const pageNumber = curr.metadata.find(i => i.key === 'pageNumber')
          ?.value;
        const pageRegions = regionsGroups(curr.regions);
        const prettyRegions = pageRegions.map(i => ({
          ...i,
          value: i.id,
          label: `${pageNumber} - ${i.label}`
        }));
        return [...acc, ...prettyRegions];
      }, []);

      const previousArticleIdMetadataConfigIdx = mtCnfgs.findIndex(
        i => i.key === 'previousArticleId'
      );
      if (previousArticleIdMetadataConfigIdx !== -1) {
        mtCnfgs[previousArticleIdMetadataConfigIdx] = {
          ...mtCnfgs[previousArticleIdMetadataConfigIdx],
          options: [
            {
              value: '',
              label: 'Choose label'
            },
            ...articles
          ],
          selectable: true
        };
      }

      // set annotationData
      const imagesWithMetadata = imgs.map(image => {
        return {
          ...image,
          metadata: addLevelMetadata(
            image.metadata || [],
            mtCnfgs.filter(mcf => mcf.level === 'photo')
          ),
          regions: addMetadataToRegions(
            image.regions,
            mtCnfgs.filter(mcf => mcf.level.startsWith('photo_'))
          )
        };
      });
      // set colors for classes
      const annotationClasses = modelClasses(source.annotationConfig.models[0]);
      const clsColors = annotationClasses
        ? annotationClasses.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.name]: curr.color
          };
        }, {})
        : defaultClsColors;

      setAnnotationData(prev => ({
        ...prev,
        images: imagesWithMetadata,
        albumMetadata: addLevelMetadata(
          album.metadata || [],
          mtCnfgs.filter(mcf => mcf.level === 'album')
        ),
        metadataConfigs: mtCnfgs,
        isReady: isAlbumReady(album),
        classColors: clsColors,
        loading: false
      }));
    };

    updateAnnotationData(data.getAlbum);
  }, [data, sources, modelClasses]);

  return {
    loading,
    isReady,
    error: error || annotationDataError,
    images,
    classColors,
    albumMetadata,
    save,
    fetchImage,
    metadataConfigs
  };
}

export default useAnnotation;
