import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

function CaseNavigation({ currentCaseId, filteredCaseIds }) {
  const { t } = useTranslation();

  if (filteredCaseIds.length < 1) {
    return null;
  }

  const currentCaseIndex = _.indexOf(filteredCaseIds, currentCaseId);
  const previousCaseId =
    currentCaseIndex - 1 < 0 ? null : filteredCaseIds[currentCaseIndex - 1];
  const nextCaseId =
    currentCaseIndex + 1 >= filteredCaseIds.length
      ? null
      : filteredCaseIds[currentCaseIndex + 1];

  return (
    <div className="pb-3 pl-3 d-flex flex-row justify-content-between">
      <div>
        <Link
          to={{
            pathname: `/cases/${previousCaseId}`,
            state: {
              filteredCaseIds
            }
          }}
          className={classnames({
            'disabled-link': !previousCaseId
          })}
        >
          {' '}
          <Button
            className="btn-icon btn-3"
            color="default"
            type="button"
            disabled={!previousCaseId}
          >
            <span className="btn-inner--icon">
              <i className="fas fa-caret-left" />
            </span>
            <span className="btn-inner--text">{t('general.previous')}</span>
          </Button>
        </Link>
        <Link
          to={{
            pathname: `/cases/${nextCaseId}`,
            state: {
              filteredCaseIds
            }
          }}
          className={classnames({
            'disabled-link': !nextCaseId
          })}
        >
          {' '}
          <Button
            className="btn-icon btn-3"
            color="default"
            type="button"
            disabled={!nextCaseId}
          >
            <span className="btn-inner--text">{t('general.next')} </span>
            <span className="btn-inner--icon">
              <i className="fas fa-caret-right" />
            </span>
          </Button>
        </Link>
      </div>
    </div>
  );
}

CaseNavigation.propTypes = {
  currentCaseId: PropTypes.string.isRequired,
  filteredCaseIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CaseNavigation;
