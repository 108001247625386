const MAX_GROUP_LENGTH = 20;
const ignoredClasses = ['metadata', 'advertisement', 'photo_caption', 'image'];

function regionsGroups(regions) {
  if (!regions) {
    return [];
  }
  const groups = regions
    .filter(i => !ignoredClasses.includes(i.cls))
    .reduce((acc, curr) => {
      const { groupId } = curr;
      if (acc.some(e => e.id === groupId)) {
        return acc;
      }
      acc.push({
        id: groupId,
        label: curr?.text?.substring(0, MAX_GROUP_LENGTH) || groupId
      });
      return acc;
    }, []);

  return groups;
}

export default regionsGroups;
