function addLevelMetadata(metadata = [], metadataConfigs) {
  if (!metadata) {
    return metadataConfigs.map(mcf => ({
      key: mcf.key,
      value: ''
    }));
  }
  return metadataConfigs.reduce(
    (prev, curr) => {
      const existingMetadataIdx = prev.findIndex(mt => mt.key === curr.key);
      if (existingMetadataIdx === -1) {
        prev.push({
          key: curr.key,
          value: ''
        });
      } else {
        prev[existingMetadataIdx] = {
          ...prev[existingMetadataIdx]
        };
      }
      return prev;
    },
    [...metadata]
  );
}

export default addLevelMetadata;
