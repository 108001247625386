const defaultRegionClassList = [
  'text',
  'title',
  'subtitle',
  'author',
  'appendix',
  'image',
  'photo_caption',
  'photo_author',
  'interview',
  'table',
  'about_author',
  'advertisement',
  'other_graphics',
  'unknown',
  'section'
];

export default defaultRegionClassList;
