// WARNING: DO NOT EDIT. This file is automatically generated and will be overwritten.
// Environment: dev
// Insurance name: triglavsl
const awsmobile = {
"aws_project_region": "eu-west-1",
"aws_user_files_s3_bucket_region": "eu-west-1",
"aws_cognito_region": "eu-west-1",
"aws_content_delivery_bucket_region": "eu-west-1",
"aws_appsync_region": "eu-west-1",
"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
"aws_appsync_graphqlEndpoint": "https://xzwxbrw53rfhpnisrb5bovlrna.appsync-api.eu-west-1.amazonaws.com/graphql",
"aws_user_pools_id": "eu-west-1_sALujNGM1",
"aws_content_delivery_url": "https://dj4x167joatj2.cloudfront.net",
"aws_user_pools_web_client_id": "33ihada893087mnqjio55ejob2",
"aws_user_files_s3_bucket": "triglavsl-classy-storage",
"aws_cognito_identity_pool_id": "eu-west-1:1b5c68da-72bf-40ef-b747-88c499bb66da",
"aws_content_delivery_bucket": "triglavsl-classy-hosting"
};
export default awsmobile;
