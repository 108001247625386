import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import useAmplifyAuth from 'utils/useAmplifyAuth';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';

const accountContext = createContext();

const GET_ACCOUNT = gql`
  query getAccount {
    getAccount {
      id
      name
      keys
      models {
        displayName
        modelFamily
        displayType
      }
      sources {
        filterName
        name
        hasStats
        hasExports
        hasVideo
        hasInspections
        requiredFields {
          fieldId
          fieldText
          label
        }
        annotationConfig {
          models
          metadataConfigs {
            key
            level
            options
          }
        }
      }
      models {
        modelFamily
        classes {
          color
          name
        }
      }
      metrics {
        namespace
        displayName
      }
    }
  }
`;

function useProvideAccount() {
  const client = useApolloClient();
  const [account, setAccount] = useState({ loading: true });
  const [focusedSource, setFocusedSource] = useState(null);
  const {
    state: { user }
  } = useAmplifyAuth();

  const modelClasses = modelName => {
    const model = account.models.find(m => m.modelFamily === modelName);
    if (!model) {
      return [];
    }
    if (!model.classes) {
      return [];
    }
    return model.classes;
  };

  useEffect(() => {
    async function getAccount() {
      try {
        const res = await client.query({
          query: GET_ACCOUNT
        });
        setAccount({ ...res.data.getAccount, loading: false });
        setFocusedSource(res.data.getAccount.sources[0].filterName);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    getAccount();
  }, [client, user]);

  return {
    account,
    sources: account?.sources || [],
    metrics: account?.metrics || [],
    focusedSource,
    setFocusedSource,
    modelClasses
  };
}

export function AccountProvider({ children }) {
  const { t } = useTranslation();
  const account = useProvideAccount();
  if (account.account.loading === true) {
    return <Loader title={t('general.loading_account')} />;
  }

  return (
    <accountContext.Provider value={account}>
      {children}
    </accountContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useAccount = () => {
  return useContext(accountContext);
};
