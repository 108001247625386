import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { ApolloLink, concat } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import awsconfig from 'aws-exports';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';
import isValidJWT from './isValidJWT';

const noCacheOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

function loadAccessToken() {
  const userName = localStorage.getItem(
    `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.LastAuthUser`
  );
  const accessToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.${userName}.accessToken`
  );
  if (!accessToken) {
    throw new Error(`unable to load accessToken from localStorage`);
  }
  return accessToken;
}

const createApolloClient = ({ uri, cache }) => {
  const asyncAuthMiddleware = setContext(() =>
    Auth.currentSession().then(() => {
      return {
        headers: {
          Authorization: `${loadAccessToken()}`
        }
      };
    })
  );

  const uploadLink = createUploadLink({
    uri: uri || awsconfig.aws_appsync_graphqlEndpoint,
    credentials: 'same-origin',
    headers: {
      'keep-alive': 'true'
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            // eslint-disable-next-line no-console
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          // eslint-disable-next-line no-console
          console.error(`[Network error]: ${networkError}`);
          if (
            networkError.statusCode === 401 &&
            !isValidJWT(localStorage.getItem('token'))
          ) {
            const redirectTo = window.location.pathname;
            window.location.replace(
              `/login${
                redirectTo.length > 1 ? `?redirectTo=${redirectTo}` : ''
              }`
            );
          }
          if (networkError === 'No current user') {
            const redirectTo = window.location.pathname;
            window.location.replace(
              `/login${
                redirectTo.length > 1 ? `?redirectTo=${redirectTo}` : ''
              }`
            );
          }
        }
      }),
      concat(asyncAuthMiddleware, uploadLink)
    ]),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: !cache && noCacheOptions
  });

  return client;
};

export default createApolloClient;
