function isPhotoCalculated(photo) {
  // if there is no updatedAt, consider OK (backwards compatible)
  if (!photo.updatedAt) {
    return true;
  }

  // if updateAt defined and greater than createdAt, consider OK
  if (photo.updatedAt > photo.createdAt) {
    return true;
  }

  return false;
}

function isAlbumReady(album) {
  // no images
  if (album?.photos?.length < 1) {
    return false;
  }

  // check if all photos have either modelResults of editeModelResults
  let somePhotoNotCalculated = false;
  album.photos.every(photo => {
    if (!isPhotoCalculated(photo)) {
      somePhotoNotCalculated = true;
      return false;
    }
    return true;
  });

  if (somePhotoNotCalculated) {
    return false;
  }

  return true;
}

export default isAlbumReady;
