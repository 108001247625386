import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SummaryV2Row from './SummaryV2Row';
import { compareByNameAsc } from '../utils/compareFunctions';

function SummaryV2({ title, rows = [] }) {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-capitalize">{t(`summary.${title}`)}</h3>
      <Table className="align-items-center" responsive>
        <tbody>
          {rows.length === 0 && <h5>{t('summary.no_data')}</h5>}
          {rows.length > 0 &&
            [...rows].sort(compareByNameAsc).map(r => <SummaryV2Row row={r} />)}
        </tbody>
      </Table>
    </>
  );
}

SummaryV2.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      layout: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      count: PropTypes.number,
      score: PropTypes.number,
      check: PropTypes.bool
    })
  ).isRequired
};

export default SummaryV2;
