const defaultClsColors = {
  title: "#f70202",
  subtitle: "#ffb405",
  text: "#14deef",
  author: "#f8d51e",
  appendix: "#bfede2",
  photo_author: "#9a17bb",
  photo_caption: "#ff84f6",
  advertisement: "#ffb201",
  other_graphics: "#ff5400",
  unknown: "#bfede2",
  about_author: "#9a17bb",
  image: "#14deef",
  interview: "#23b20f",
  table: "#02b4ba",
  section: "#ffcccc",
};

export default defaultClsColors;
