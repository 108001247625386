const metadataRegionsToModelResults = regions => {
  const groupedModelResults = regions.map(r => {
    const { groupId, text } = r;
    const parsedMetadataKeyValues = JSON.parse(text);
    const metadataItems = parsedMetadataKeyValues.map(i => ({
      id: i.metadataId,
      groupId,
      text: i.value,
      label: i.key,
      score: 1,
      box: {
        X1: r.x,
        Y1: r.y,
        X2: r.x + r.w,
        Y2: r.y + r.h
      }
    }));
    return metadataItems;
  });

  return groupedModelResults.reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);
};

export default metadataRegionsToModelResults;
