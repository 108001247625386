const colorful = [
  '#e3a7c0',
  '#c2d5a8',
  '#f2e9cc',
  '#bad5f0',
  '#f0d5ba',
  '#d6eff5',
  '#f8d7e8',
  '#a5d5d5',
  '#b0abcb',
  '#fae4cc'
];

const greyscale = ['#343434', '#989898', '#dcdcdc'];

export { colorful, greyscale };
